import { createRouter, createWebHistory } from "vue-router";
import Start from "@/views/Start.vue";
import Login from "@/views/Login.vue";
import PeerMentorHeader from "@/components/PeerMentorHeader.vue";
import Logout from "@/views/Logout.vue";
import LogoutHeader from "@/components/LogoutHeader.vue";

// NB only the router is aware of URLs. The route names are used exclusively throughout
// the rest of the application
// Meta field defaults:
// requiresAuth: true
// forceActions: true
// allowedUserTypes: no default. Not required if requiresAuth is false, otherwise must be an array
//  The array's elements should be user type values that match the values returned by app.getUserTypes
//
// Meta filed defaults will be set automatically. You only need to specify meta values when
// overriding the default values. Note that setting requiresAuth to false automatically sets
// forceActions to false.
const routes = [
  {
    path: "/",
    redirect: '/login', // Redirect to login until we have a public home page built
    name: "Start",
    components: {
      default: Start,
      Header: PeerMentorHeader,
    },
    props: {
      Header: {
        showMenu: false,
        showLoginText: false,
      },
    },
    meta: { requiresAuth: false },
  },
  {
    path: "/login",
    name: "Login",
    components: {
      default: Login,
      Header: PeerMentorHeader,
    },
    props: {
      Header: {
        showMenu: false,
        showLoginText: true,
      },
    },
    meta: { requiresAuth: false },
  },
  {
    path: "/first-login",
    name: "FirstLogin",
    components: {
      default: () =>
        import(
          /* webpackChunkName: "FirstLogin" */ "../views/FirstLogin.vue"
        ),
      Header: PeerMentorHeader,
    },
    props: {
      Header: { showMenu: false },
    },
    meta: {
      forceActions: false, // tells router.beforeEach() to serve the view even if user requires action(s)
      showUrgentAlert: false,
      requiresAuth: false,
    },
  },
  {
    path: "/resend-invite",
    name: "ResendInvitation",
    components: {
      default: () =>
        import(
          /* webpackChunkName: "ResendInvitation;" */ "../views/ResendInvitation.vue"
        ),
        Header: PeerMentorHeader,
    },
    props: {
      default: {
        pageTitle: "screens.Apply.pageTitle",
      },
      Header: { showMenu: false },
    },
    meta: { requiresAuth: false },
  },
  {
    path: "/apply/:type",
    name: "ApplicationStart",
    components: {
      default: () =>
        import(
          /* webpackChunkName: "StartPeer;" */ "../views/ApplicationStart.vue"
        ),
        Header: () =>
          import(
            /* webpackChunkName: "StartPeer;" */ "../components/ApplicationHeader.vue"
        ),
    },
    props: {
      default: {
        pageTitle: "screens.Apply.pageTitle",
      }
    },
    meta: { requiresAuth: false },
  },
  {
    path: "/email-verification",
    name: "ApplicationEmailSubmitted",
    components: {
      default: () =>
        import(
          /* webpackChunkName: "Application;" */ "../views/ApplicationEmailVerification.vue"
        ),
        Header: () =>
          import(
            /* webpackChunkName: "Application;" */ "../components/EmailSubmittedHeader.vue"
        ),
    },
    props: {
      default: {
        pageTitle: "screens.Apply.pageTitle",
      }
    },
    meta: { requiresAuth: false },
  },
  // {
  //   path: "/mentor-apply",
  //   name: "ApplicationStart",
  //   components: {
  //     default: () =>
  //       import(
  //         /* webpackChunkName: "Start;" */ "../views/ApplicationStart.vue"
  //       ),
  //       Header: () =>
  //         import(
  //           /* webpackChunkName: "Start;" */ "../components/ApplicationPeerHeader.vue"
  //       ),
  //   },
  //   props: {
  //     default: {
  //       pageTitle: "screens.Apply.mentorHeader",
  //     },
  //     Header: {
  //       type: "mentor",
  //     }
  //   },
  //   meta: { requiresAuth: false },
  // },
  {
    path: "/peer-application",
    name: "PeerApplication",
    components: {
      default: () =>
        import(
          /* webpackChunkName: "Application;" */ "../views/ApplicationPeer.vue"
        ),
        Header: () =>
          import(
            /* webpackChunkName: "Application;" */ "../components/ApplicationPeerHeader.vue"
        ),
    },
    props: {
      default: {
        pageTitle: "screens.Apply.menteeHeader",
      },
    },
    meta: { requiresAuth: false },
  },
  {
    path: "/mentor-application",
    name: "MentorApplication",
    components: {
      default: () =>
        import(
          /* webpackChunkName: "Application;" */ "../views/ApplicationMentor.vue"
        ),
        Header: () =>
          import(
            /* webpackChunkName: "Application;" */ "../components/ApplicationMentorHeader.vue"
        ),
    },
    props: {
      default: {
        pageTitle: "screens.Apply.mentorHeader",
      },
    },
    meta: { requiresAuth: false },
  },
  {
    path: "/application",
    name: "Application",
    components: {
      default: () =>
        import(
          /* webpackChunkName: "Application;" */ "../views/Application.vue"
        ),
        Header: () =>
          import(
            /* webpackChunkName: "Application;" */ "../components/ApplicationPeerHeader.vue"
        ),
    },
    props: {
      default: {
        pageTitle: "screens.Apply.pageHeader",
      },
    },
    meta: { requiresAuth: false },
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    components: {
      default: () =>
        import(
          /* webpackChunkName: "ForgotPassword;" */ "../views/ForgotPassword.vue"
        ),
      Header: () =>
        import(
          /* webpackChunkName: "ForgotPassword;" */ "../components/ForgotPasswordHeader.vue"
        ),
    },
    props: {
      default: {
        pageTitle: "screens.ForgotPassword.pageTitle",
      },
    },
    meta: { requiresAuth: false },
  },
  {
    path: "/forgot-password-confirmation",
    name: "ForgotPasswordConfirmation",
    components: {
      default: () =>
        import(
          /* webpackChunkName: "ForgotPassword;" */ "../views/ForgotPasswordConfirmation.vue"
        ),
      Header: () =>
        import(
          /* webpackChunkName: "ForgotPassword;" */ "../components/ForgotPasswordConfirmationHeader.vue"
        ),
    },
    props: {
      default: {
        pageTitle: "screens.ForgotPasswordConfirmation.pageTitle",
      },
    },
    meta: { requiresAuth: false },
  },
  {
    path: "/forgot-password-reset",
    name: "ForgotPasswordReset",
    components: {
      default: () =>
        import(
          /* webpackChunkName: "ForgotPassword;" */ "../views/ForgotPasswordReset.vue"
        ),
        Header: PeerMentorHeader,
    },
    props: {
      default: {
        pageTitle: "screens.ForgotPasswordConfirmation.pageTitle",
      },
      Header: {
        showMenu: false,
        showLoginText: false,
      },
    },
    meta: { requiresAuth: false },
  },
  {
    path: "/logout/:survey",
    name: "Logout",
    components: {
      default: Logout,
      Header: LogoutHeader,
    },
    props: {
      default: {
        pageTitle: "screens.Logout.pageTitle",
      },
    },
    meta: { requiresAuth: false },
  },
  {
    path: "/consent",
    name: "Consent",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    components: {
      default: () =>
        import(
          /* webpackChunkName: "Interstitial" */ "../views/Interstitial.vue"
        ),
      Header: PeerMentorHeader,
    },
    props: {
      Header: { showMenu: false },
    },
    meta: {
      forceActions: false, // tells router.beforeEach() to serve the view even if user requires action(s)
      showUrgentAlert: false,
      allowedUserTypes: ["mentee", "mentor", "coord"],
    },
  },
  {
    path: "/survey",
    name: "Survey",
    components: {
      default: () =>
        import(/* webpackChunkName: "Survey" */ "../views/Survey.vue"),
      Header: PeerMentorHeader,
    },
    meta: {
      forceActions: false, // tells router.beforeEach() to serve the view even if user requires action(s)
      showUrgentAlert: false,
      allowedUserTypes: ["mentee", "mentor"],
    },
  },
  {
    path: "/welcome",
    name: "PeerMentorSplashScreen",
    components: {
      default: () =>
        import(
          /* webpackChunkName: "PeerMentorSplashScreen" */ "../views/PeerMentorSplashScreen.vue"
        ),
      Header: PeerMentorHeader,
    },
    meta: {
      forceActions: true, // tells router.beforeEach() to serve the view even if user requires action(s)
      showUrgentAlert: false,
      allowedUserTypes: ["mentee", "mentor"],
    },
  },
  {
    path: "/home",
    name: "Home",
    components: {
      default: () =>
        import(
          /* webpackChunkName: "PeerMentorHome;" */ "../views/PeerMentorHome.vue"
        ),
      Header: PeerMentorHeader,
    },
    props: {
      default: {
        pageTitle: "screens.PeerMentorHome.pageTitle",
      },
      Header: {
        headerClass: 'dashboard--header',
        titleClass: 'dashboard--title'
      }
    },
    meta: {
      allowedUserTypes: ["mentee", "mentor"],
    },
  },
  {
    path: "/videos/:id",
    name: "PeerMentorVideos",
    components: {
      default: () =>
        import(
          /* webpackChunkName: "PeerMentorVideos;" */ "../views/PeerMentorVideos.vue"
        ),
      Header: () =>
        import(
          /* webpackChunkName: "PeerMentorHeaderShortNoTitle;" */ "../components/PeerMentorHeaderShortNoTitle.vue"
        ),
    },
    props: {
      default: {
        pageTitle: "screens.PeerMentorVideos.pageTitle",
      },
      Header: {
        backLinkText: "screens.PeerMentorAccountInfo.backLinkText",
        backLinkRoute: "Home",
      },
    },
    meta: {
      allowedUserTypes: ["mentor,mentee"],
    },
  },
  {
    path: "/chat/:user",
    name: "Messaging",
    components: {
      default: () =>
        import(
          /* webpackChunkName: "PeerMentorMessaging;" */ "../views/PeerMentorMessaging.vue"
        ),
      Header: () =>
        import(
          /* webpackChunkName: "PeerMentorMessaging;" */ "../components/PeerMentorMessagingHeader.vue"
        ),
    },
    props: {
      default: {
        pageTitle: "screens.PeerMentorMessaging.pageTitle",
      },
      Header: {
        showBorder: false,
      },
    },
    meta: {
      allowedUserTypes: ["mentee", "mentor"],
    },
  },
  {
    path: "/questions-for-mentor",
    name: "PeerQuestions",
    components: {
      default: () =>
        import(
          /* webpackChunkName: "PeerQuestions;" */ "../views/PeerQuestions.vue"
        ),
      Header: () =>
        import(
          /* webpackChunkName: "PeerMentorHeaderShortNoTitle;" */ "../components/PeerMentorHeaderShortNoTitle.vue"
        ),
    },
    props: {
      default: {
        pageTitle: "screens.PeerMentorHome.pageTitle",
      },
      Header: {
        backLinkText: "global.defaultBackLinkText",
        backLinkRoute: "SkillsList",
      },
    },
    meta: {
      allowedUserTypes: ["mentee"],
    },
  },
  {
    path: "/questions-for-doctor",
    name: "PeerQuestionsDoctor",
    components: {
      default: () =>
        import(
          /* webpackChunkName: "PeerQuestionsDoctor;" */ "../views/PeerQuestionsDoctor.vue"
        ),
      Header: () =>
        import(
          /* webpackChunkName: "PeerMentorHeaderShortNoTitle;" */ "../components/PeerMentorHeaderShortNoTitle.vue"
        ),
    },
    props: {
      default: {
        pageTitle: "screens.PeerMentorHome.pageTitle",
      },
      Header: {
        backLinkText: "global.defaultBackLinkText",
        backLinkRoute: "SkillsList",
      },
    },
    meta: {
      allowedUserTypes: ["mentee"],
    },
  },
  // This version of the chat URL sets the session notes to the default view
  {
    path: "/chat/:user",
    name: "Messaging/SessionNotes",
    components: {
      default: () =>
        import(
          /* webpackChunkName: "PeerMentorMessaging;" */ "../views/PeerMentorMessaging.vue"
        ),
      Header: () =>
        import(
          /* webpackChunkName: "PeerMentorHeaderShort;" */ "../components/PeerMentorHeaderShort.vue"
        ),
    },
    props: {
      default: {
        pageTitle: "screens.PeerMentorMessaging.pageTitle",
        activeTab: "Session Notes",
      },
      Header: {
        showBorder: false,
      },
    },
    meta: {
      allowedUserTypes: ["mentor"],
    },
  },
  {
    path: "/session-note/:user/:note",
    name: "MentorSessionNotesDetail",
    components: {
      default: () =>
        import(
          /* webpackChunkName: "MentorSessionNotesDetail;" */ "../views/MentorSessionNotesDetail.vue"
        ),
      Header: () =>
        import(
          /* webpackChunkName: "PeerMentorHeaderShortNoTitle;" */ "../components/PeerMentorHeaderShortNoTitle.vue"
        ),
    },
    props: {
      default: {
        pageTitle: "screens.MentorSessionNotesDetail.pageTitle",
      },
      Header: {
        backLinkText: "screens.PeerMentorAccountInfo.backLinkText",
        backLinkRoute: "Home",
      },
    },
    meta: {
      allowedUserTypes: ["mentor"],
    },
  },
  {
    path: "/add-session-notes",
    name: "SessionNotesAdd",
    components: {
      default: () =>
        import(
          /* webpackChunkName: "SessionNotesAdd;" */ "../views/SessionNotesAdd.vue"
        ),
      Header: () =>
        import(
          /* webpackChunkName: "PeerMentorHeaderShort;" */ "../components/PeerMentorHeaderShort.vue"
        ),
    },
    props: {
      default: {
        pageTitle: "screens.SessionNotesAdd.pageTitle",
      },
    },
    meta: {
      allowedUserTypes: ["mentor"],
    },
  },
  {
    path: "/add-session-notes/:user",
    name: "SessionNotesAddUser",
    components: {
      default: () =>
        import(
          /* webpackChunkName: "SessionNotesAdd;" */ "../views/SessionNotesAdd.vue"
        ),
      Header: () =>
        import(
          /* webpackChunkName: "PeerMentorHeaderShort;" */ "../components/PeerMentorHeaderShort.vue"
        ),
    },
    props: {
      default: {
        pageTitle: "screens.SessionNotesAdd.pageTitle",
      },
    },
    meta: {
      allowedUserTypes: ["mentor"],
    },
  },
  {
    path: "/resource-library",
    name: "ResourcesList",
    components: {
      default: () =>
        import(
          /* webpackChunkName: "ResourcesList;" */ "../views/ResourcesList.vue"
        ),
      Header: () =>
        import(
          /* webpackChunkName: "PeerMentorHeaderShortNoTitle;" */ "../components/PeerMentorHeaderShortNoTitle.vue"
        ),
    },
    props: {
      default: {
        pageTitle: "screens.ResourcesList.pageTitle",
        defaultTab: "All Articles",
        category: "health", // We'll replace this with the client-specific value in the component
      },
    },
    meta: {
      allowedUserTypes: ["mentee", "mentor"],
    },
  },
  {
    path: "/resource-library/:id",
    name: "ResourceDetail",
    components: {
      default: () =>
        import(
          /* webpackChunkName: "ResourceDetail;" */ "../views/ResourceDetail.vue"
        ),
      Header: () =>
        import(
          /* webpackChunkName: "PeerMentorHeaderShortNoTitle;" */ "../components/PeerMentorHeaderShortNoTitle.vue"
        ),
    },
    props: {
      default: {
        pageTitle: "screens.ResourcesList.pageTitle",
      },
      Header: {
        backLinkRoute: "ResourcesList",
      }
    },
    meta: {
      allowedUserTypes: ["mentee", "mentor"],
    },
  },
  {
    path: "/admin/health-library",
    name: "AdminHealthLibrary",
    components: {
      default: () =>
        import(
          /* webpackChunkName: "AdminResources;" */ "../views/AdminResourcesList.vue"
        ),
      Header: () =>
        import(
          /* webpackChunkName: "AdminResources;" */ "../components/AdminHeaderResources.vue"
        ),
    },
    props: {
      default: {
        pageTitle: "screens.ResourcesList.pageTitle",
        defaultTab: "All Articles",
        category: "health", // We'll replace this with the client-specific value in the component
      },
    },
    meta: {
      allowedUserTypes: ["coord"],
    },
  },
  {
    path: "/tips",
    name: "MentoringTips",
    components: {
      default: () =>
        import(
          /* webpackChunkName: "ResourcesList;" */ "../views/PeerMentorTips.vue"
        ),
      Header: () =>
        import(
          /* webpackChunkName: "PeerMentorHeaderShortNoTitle;" */ "../components/PeerMentorHeaderShortNoTitle.vue"
        ),
    },
    props: {
      default: {
        pageTitle: "screens.Tips.pageTitleMentor",
      },
    },
    meta: {
      allowedUserTypes: ["mentor"],
    },
  },
  {
    path: "/skills",
    name: "SkillsList",
    components: {
      default: () =>
        import(
          /* webpackChunkName: "ResourcesList;" */ "../views/SkillsList.vue"
        ),
      Header: () =>
        import(
          /* webpackChunkName: "PeerMentorHeaderShortNoTitle;" */ "../components/PeerMentorHeaderShortNoTitle.vue"
        ),
    },
    props: {
      default: {
        pageTitle: "screens.Tips.pageTitleMentor",
      },
      Header: {
        backLinkText: "global.buttons.returnHome",
        backLinkRoute: "Home",
      },
    },
    meta: {
      allowedUserTypes: ["mentee", "mentor"],
    },
  },
  {
    path: "/skills/:id",
    name: "SkillsDetail",
    components: {
      default: () =>
        import(
          /* webpackChunkName: "ResourcesList;" */ "../views/SkillsDetail.vue"
        ),
      Header: () =>
        import(
          /* webpackChunkName: "PeerMentorHeaderShortNoTitle;" */ "../components/PeerMentorHeaderShortNoTitle.vue"
        ),
    },
    props: {
      default: {
        pageTitle: "screens.Tips.pageTitleMentor",
      },
      Header: {
        backLinkText: "global.defaultBackLinkText",
        backLinkRoute: "SkillsList",
      },
    },
    meta: {
      allowedUserTypes: ["mentee", "mentor"],
    },
  },
  {
    path: "/admin/skills/:id",
    name: "AdminSkillsDetail",
    components: {
      default: () =>
        import(
          /* webpackChunkName: "ResourcesList;" */ "../views/AdminSkillsDetail.vue"
        ),
      Header: () =>
        import(
          /* webpackChunkName: "ResourcesList;" */ "../components/AdminHeaderResources.vue"
        ),
    },
    props: {
      default: {
        pageTitle: "screens.Tips.pageTitleMentor",
      }
    },
    meta: {
      allowedUserTypes: ["coord"],
    },
  },
  {
    path: "/questions",
    name: "DoctorQuestions",
    components: {
      default: () =>
        import(
          /* webpackChunkName: "ResourcesList;" */ "../views/PeerMentorTips.vue"
        ),
      Header: () =>
        import(
          /* webpackChunkName: "PeerMentorHeaderShortNoTitle;" */ "../components/PeerMentorHeaderShortNoTitle.vue"
        ),
    },
    props: {
      default: {
        pageTitle: "screens.Tips.pageTitlePeer",
      },
    },
    meta: {
      allowedUserTypes: ["mentee"],
    },
  },
  {
    path: "/mange-health-resources",
    name: "ManageHealthResources",
    components: {
      default: () =>
        import(
          /* webpackChunkName: "ResourcesList;" */ "../views/ResourcesList.vue"
        ),
      Header: () =>
        import(
          /* webpackChunkName: "PeerMentorHeaderShortNoTitle;" */ "../components/PeerMentorHeaderShortNoTitle.vue"
        ),
    },
    props: {
      default: {
        pageTitle: "screens.ResourcesList.pageTitleCommunity",
        defaultTab: "All Articles",
        category: "manage_health", // We'll replace this with the client-specific value in the component
      },
    },
    meta: {
      allowedUserTypes: ["mentee", "mentor"],
    },
  },
  {
    path: "/admin/patient-stories",
    name: "AdminPatientStories",
    components: {
      default: () =>
        import(
          /* webpackChunkName: "ResourcesList;" */ "../views/AdminResourcesList.vue"
        ),
      Header: () =>
        import(
          /* webpackChunkName: "PeerMentorHeaderShortNoTitle;" */ "../components/AdminHeaderResources.vue"
        ),
    },
    props: {
      default: {
        pageTitle: "screens.ResourcesList.pageTitleCommunity",
        defaultTab: "All Articles",
        category: "manage_health", // We'll replace this with the client-specific value in the component
      },
    },
    meta: {
      allowedUserTypes: ["coord"],
    },
  },
  {
    path: "/community-resources",
    name: "CommunityResources",
    components: {
      default: () =>
        import(
          /* webpackChunkName: "ResourcesList;" */ "../views/ResourcesList.vue"
        ),
      Header: () =>
        import(
          /* webpackChunkName: "PeerMentorHeaderShortNoTitle;" */ "../components/PeerMentorHeaderShortNoTitle.vue"
        ),
    },
    props: {
      default: {
        pageTitle: "screens.ResourcesList.pageTitleCommunity",
        defaultTab: "All Articles",
        category: "community", // We'll replace this with the client-specific value in the component
      },
    },
    meta: {
      allowedUserTypes: ["mentee", "mentor"],
    },
  },
  {
    path: "/admin/community-resources",
    name: "AdminCommunityResources",
    components: {
      default: () =>
        import(
          /* webpackChunkName: "AdminResources;" */ "../views/AdminResourcesList.vue"
        ),
      Header: () =>
        import(
          /* webpackChunkName: "AdminResources;" */ "../components/AdminHeaderResources.vue"
        ),
    },
    props: {
      default: {
        pageTitle: "screens.ResourcesList.pageTitleCommunity",
        defaultTab: "All Articles",
        category: "community", // We'll replace this with the client-specific value in the component
      },
    },
    meta: {
      allowedUserTypes: ["coord"],
    },
  },
  {
    path: "/resources",
    name: "ClientResources",
    components: {
      default: () =>
        import(
          /* webpackChunkName: "ResourcesList;" */ "../views/ResourcesList.vue"
        ),
      Header: () =>
        import(
          /* webpackChunkName: "PeerMentorHeaderShortNoTitle;" */ "../components/PeerMentorHeaderShortNoTitle.vue"
        ),
    },
    props: {
      default: {
        pageTitle: "screens.ResourcesList.pageTitleCommunity",
        defaultTab: "All Articles",
        category: "resources", // We'll replace this with the client-specific value in the component
      },
    },
    meta: {
      allowedUserTypes: ["mentee", "mentor"],
    },
  },
  {
    path: "/admin/client-resources",
    name: "AdminClientResources",
    components: {
      default: () =>
        import(
          /* webpackChunkName: "AdminResources;" */ "../views/AdminResourcesList.vue"
        ),
      Header: () =>
        import(
          /* webpackChunkName: "AdminResources;" */ "../components/AdminHeaderResources.vue"
        ),
    },
    props: {
      default: {
        pageTitle: "screens.ResourcesList.pageTitleCommunity",
        defaultTab: "All Articles",
        category: "resources", // We'll replace this with the client-specific value in the component
      },
    },
    meta: {
      allowedUserTypes: ["coord"],
    },
  },
  {
    path: "/account-info",
    name: "AccountInfo",
    components: {
      default: () =>
        import(
          /* webpackChunkName: "PeerMentorAccountInfo;" */ "../views/PeerMentorAccountInfo.vue"
        ),
      Header: () =>
        import(
          /* webpackChunkName: "PeerMentorHeaderShortNoTitle;" */ "../components/PeerMentorHeaderShortNoTitle.vue"
        ),
    },
    props: {
      default: {
        pageTitle: "screens.PeerMentorAccountInfo.pageTitle",
      },
      Header: {
        backLinkText: "screens.PeerMentorAccountInfo.backLinkText",
      },
    },
    meta: {
      allowedUserTypes: ["mentee", "mentor"],
    },
  },
  {
    path: "/account-info/personal",
    name: "AccountInfoPersonal",
    components: {
      default: () =>
        import(
          /* webpackChunkName: "PeerMentorAccountInfo;" */ "../views/PeerMentorAccountInfoPersonal.vue"
        ),
      Header: () =>
        import(
          /* webpackChunkName: "PeerMentorHeaderShortNoTitle;" */ "../components/PeerMentorHeaderShortNoTitle.vue"
        ),
    },
    props: {
      default: {
        pageTitle: "screens.PeerMentorAccountInfoPersonal.pageTitle",
      },
      Header: {
        backLinkText: "screens.PeerMentorAccountInfoPersonal.backLinkText",
        backLinkRoute: "AccountInfo",
      },
    },
    meta: {
      allowedUserTypes: ["mentee", "mentor"],
    },
  },
  {
    path: "/account-info/password",
    name: "AccountInfoPassword",
    components: {
      default: () =>
        import(
          /* webpackChunkName: "PeerMentorAccountInfo;" */ "../views/PeerMentorAccountInfoPassword.vue"
        ),
      Header: () =>
        import(
          /* webpackChunkName: "PeerMentorHeaderShortNoTitle;" */ "../components/PeerMentorHeaderShortNoTitle.vue"
        ),
    },
    props: {
      default: {
        pageTitle: "screens.PeerMentorAccountInfoPassword.pageTitle",
      },
      Header: {
        backLinkText: "screens.PeerMentorAccountInfoPassword.backLinkText",
        backLinkRoute: "AccountInfo",
      },
    },
    meta: {
      allowedUserTypes: ["mentee", "mentor"],
    },
  },
  {
    path: "/account-info/language",
    name: "AccountInfoLanguage",
    components: {
      default: () =>
        import(
          /* webpackChunkName: "PeerMentorAccountInfo;" */ "../views/PeerMentorAccountInfoLanguage.vue"
        ),
      Header: () =>
        import(
          /* webpackChunkName: "PeerMentorHeaderShortNoTitle;" */ "../components/PeerMentorHeaderShortNoTitle.vue"
        ),
    },
    props: {
      default: {
        pageTitle: "screens.PeerMentorAccountInfoLanguage.pageTitle",
      },
      Header: {
        backLinkText: "screens.PeerMentorAccountInfoLanguage.backLinkText",
        backLinkRoute: "AccountInfo",
      },
    },
    meta: {
      allowedUserTypes: ["mentee", "mentor"],
    },
  },
  {
    path: "/biography",
    name: "Biography",
    components: {
      default: () =>
        import(
          /* webpackChunkName: "PeerMentorBiography;" */ "../views/PeerMentorBiography.vue"
        ),
      Header: () =>
        import(
          /* webpackChunkName: "PeerMentorHeaderShortNoTitle;" */ "../components/PeerMentorHeaderShortNoTitle.vue"
        ),
    },
    props: {
      default: {
        pageTitle: "screens.PeerMentorAccountInfoLanguage.pageTitle",
      },
      Header: {
        backLinkText: "screens.PeerMentorAccountInfo.backLinkText",
        backLinkRoute: "Home",
      },
    },
    meta: {
      allowedUserTypes: ["mentee", "mentor"],
    },
  },
  {
    path: "/biography-mentee",
    name: "BiographyMentee",
    components: {
      default: () =>
        import(
          /* webpackChunkName: "PeerBiography;" */ "../views/PeerBiography.vue"
        ),
      Header: () =>
        import(
          /* webpackChunkName: "PeerMentorHeaderShortNoTitle;" */ "../components/PeerMentorHeaderShortNoTitle.vue"
        ),
    },
    props: {
      default: {
        pageTitle: "screens.PeerMentorAccountInfoLanguage.pageTitle",
      },
      Header: {
        backLinkText: "screens.PeerMentorAccountInfo.backLinkText",
        backLinkRoute: "Home",
      },
    },
    meta: {
      allowedUserTypes: ["mentee"],
    },
  },
  {
    path: "/biography/:user",
    name: "ViewBiography",
    components: {
      default: () =>
        import(
          /* webpackChunkName: "PeerMentorBiography;" */ "../views/PeerMentorBiography.vue"
        ),
      Header: () =>
        import(
          /* webpackChunkName: "PeerMentorHeaderShortNoTitle;" */ "../components/PeerMentorHeaderShortNoTitle.vue"
        ),
    },
    props: {
      default: {
        pageTitle: "screens.PeerMentorAccountInfoLanguage.pageTitle",
      },
      Header: {
        backLinkText: "global.buttons.returnHome",
        backLinkRoute: "Home",
      },
    },
    meta: {
      allowedUserTypes: ["mentee", "mentor"],
    },
  },
  {
    path: "/agreement",
    name: "ViewAgreement",
    components: {
      default: () =>
        import(
          /* webpackChunkName: "PeerMentorDocument;" */ "../views/PeerMentorDocument.vue"
        ),
      Header: () =>
        import(
          /* webpackChunkName: "PeerMentorHeaderShortNoTitle;" */ "../components/PeerMentorHeaderShortNoTitle.vue"
        ),
    },
    props: {
      default: {
        pageTitle: "screens.PeerMentorAccountInfoLanguage.pageTitle",
        document: "agreement",
      },
      Header: {
        backLinkText: "global.buttons.returnHome",
        backLinkRoute: "Home",
      },
    },
    meta: {
      allowedUserTypes: ["mentee", "mentor"],
    },
  },
  {
    path: "/privacy",
    name: "ViewPrivacy",
    components: {
      default: () =>
        import(
          /* webpackChunkName: "PeerMentorDocument;" */ "../views/PeerMentorDocument.vue"
        ),
      Header: () =>
        import(
          /* webpackChunkName: "PeerMentorHeaderShortNoTitle;" */ "../components/PeerMentorHeaderShortNoTitle.vue"
        ),
    },
    props: {
      default: {
        pageTitle: "screens.PeerMentorAccountInfoLanguage.pageTitle",
        document: "privacy",
      },
      Header: {
        backLinkText: "global.buttons.returnHome",
        backLinkRoute: "Home",
      },
    },
    meta: {
      allowedUserTypes: ["mentee", "mentor"],
    },
  },
  {
    path: "/terms",
    name: "ViewTerms",
    components: {
      default: () =>
        import(
          /* webpackChunkName: "PeerMentorDocument;" */ "../views/PeerMentorDocument.vue"
        ),
      Header: () =>
        import(
          /* webpackChunkName: "PeerMentorHeaderShortNoTitle;" */ "../components/PeerMentorHeaderShortNoTitle.vue"
        ),
    },
    props: {
      default: {
        pageTitle: "screens.PeerMentorAccountInfoLanguage.pageTitle",
        document: "terms",
      },
      Header: {
        backLinkText: "global.buttons.returnHome",
        backLinkRoute: "Home",
      },
    },
    meta: {
      allowedUserTypes: ["mentee", "mentor"],
    },
  },
  {
    path: "/admin/join",
    name: "AdminAgreementJoin",
    components: {
      default: () =>
        import(
          /* webpackChunkName: "AdminAgreement;" */ "../views/AdminAgreementsJoin.vue"
        ),
      Header: () =>
        import(
          /* webpackChunkName: "AdminAgreement;" */ "../components/AdminHeaderAgreements.vue"
        ),
    },
    props: {
      default: {
        pageTitle: "Agreements",
      },
    },
    meta: {
      allowedUserTypes: ["coord"],
    },
  },
  {
    path: "/admin/volunteer",
    name: "AdminAgreementVolunteer",
    components: {
      default: () =>
        import(
          /* webpackChunkName: "AdminAgreement;" */ "../views/AdminAgreementsVolunteer.vue"
        ),
      Header: () =>
        import(
          /* webpackChunkName: "AdminAgreement;" */ "../components/AdminHeaderAgreements.vue"
        ),
    },
    props: {
      default: {
        pageTitle: "Agreements",
      },
    },
    meta: {
      allowedUserTypes: ["coord"],
    },
  },
  {
    path: "/admin/privacy",
    name: "AdminAgreementPrivacy",
    components: {
      default: () =>
        import(
          /* webpackChunkName: "AdminAgreement;" */ "../views/AdminAgreementsPrivacy.vue"
        ),
      Header: () =>
        import(
          /* webpackChunkName: "AdminAgreement;" */ "../components/AdminHeaderAgreements.vue"
        ),
    },
    props: {
      default: {
        pageTitle: "Agreements",
      },
    },
    meta: {
      allowedUserTypes: ["coord"],
    },
  },
  {
    path: "/admin/license",
    name: "AdminAgreementLicense",
    components: {
      default: () =>
        import(
          /* webpackChunkName: "AdminAgreement;" */ "../views/AdminAgreementsLicense.vue"
        ),
      Header: () =>
        import(
          /* webpackChunkName: "AdminAgreement;" */ "../components/AdminHeaderAgreements.vue"
        ),
    },
    props: {
      default: {
        pageTitle: "Agreements",
      },
    },
    meta: {
      allowedUserTypes: ["coord"],
    },
  },
  {
    path: "/admin/",
    name: "AdminHomePeers",
    components: {
      default: () =>
        import(/* webpackChunkName: "AdminHome;" */ "../views/AdminHome.vue"),
      Header: () =>
        import(
          /* webpackChunkName: "AdminHome;" */ "../components/AdminHeader.vue"
        ),
    },
    props: {
      default: {
        pageTitle: "Administration",
        displayUserType: "peers",
      },
      Header: {
        activeTab: "peers",
      },
    },
    meta: {
      allowedUserTypes: ["coord"],
    },
  },
  {
    path: "/admin/:user",
    name: "AdminHomePeerDisplay",
    components: {
      default: () =>
        import(/* webpackChunkName: "AdminHome;" */ "../views/AdminHome.vue"),
      Header: () =>
        import(
          /* webpackChunkName: "AdminHome;" */ "../components/AdminHeader.vue"
        ),
    },
    props: {
      default: {
        pageTitle: "Administration",
        displayUserType: "peers",
      },
      Header: {
        activeTab: "peers",
      },
    },
    meta: {
      allowedUserTypes: ["coord"],
    },
  },
  {
    path: "/admin/mentors",
    name: "AdminHomeMentors",
    components: {
      default: () =>
        import(/* webpackChunkName: "AdminHome;" */ "../views/AdminHome.vue"),
      Header: () =>
        import(
          /* webpackChunkName: "AdminHome;" */ "../components/AdminHeader.vue"
        ),
    },
    props: {
      default: {
        pageTitle: "Administration",
        displayUserType: "mentors",
      },
      Header: {
        activeTab: "mentors",
      },
    },
    meta: {
      allowedUserTypes: ["coord"],
    },
  },
  {
    path: "/admin/admins",
    name: "AdminHomeAdmins",
    components: {
      default: () =>
        import(/* webpackChunkName: "AdminHome;" */ "../views/AdminHome.vue"),
      Header: () =>
        import(
          /* webpackChunkName: "AdminHome;" */ "../components/AdminHeader.vue"
        ),
    },
    props: {
      default: {
        pageTitle: "Administration",
        displayUserType: "admins",
      },
      Header: {
        activeTab: "admins",
      },
    },
    meta: {
      allowedUserTypes: ["coord"],
    },
  },
   {
    path: "/admin/resources",
    name: "AdminResources",
    components: {
      default: () =>
        import(/* webpackChunkName: "AdminResources;" */ "../views/AdminResources.vue"),
      Header: () =>
        import(
          /* webpackChunkName: "AdminHome;" */ "../components/AdminHeader.vue"
        ),
     },
    props: {
      default: {
        pageTitle: "Resources",
      },
      Header: {
        activeTab: "",
      },
    },
    meta: {
      allowedUserTypes: ["coord"],
    },
  },
  {
    path: "/admin/profile",
    name: "AdminHomeAdminProfile",
    components: {
      default: () =>
        import(/* webpackChunkName: "AdminHome;" */ "../views/AdminHome.vue"),
      Header: () =>
        import(
          /* webpackChunkName: "AdminHome;" */ "../components/AdminHeader.vue"
        ),
    },
    props: {
      default: {
        pageTitle: "Administration",
        displayUserType: "admins",
      },
      Header: {
        activeTab: "admins",
      },
    },
    meta: {
      allowedUserTypes: ["coord"],
    },
  },
  {
    path: "/admin/matching",
    name: "AdminMatching",
    components: {
      default: () =>
        import(
          /* webpackChunkName: "AdminMatching;" */ "../views/AdminMatching.vue"
        ),
      Header: () =>
        import(
          /* webpackChunkName: "AdminHome;" */ "../components/AdminHeader.vue"
        ),
    },
    props: {
      default: {
        pageTitle: "Match Peer",
      },
      Header: {
        activeTab: "matching",
      },
    },
    meta: {
      allowedUserTypes: ["coord"],
    },
  },
  {
    path: "/admin/messages",
    name: "AdminMessaging",
    components: {
      default: () =>
        import(
          /* webpackChunkName: "AdminMatching;" */ "../views/AdminMessaging.vue"
        ),
      Header: () =>
        import(
          /* webpackChunkName: "AdminHome;" */ "../components/AdminHeader.vue"
        ),
    },
    props: {
      default: {
        pageTitle: "Messages",
      },
      Header: {
        activeTab: "messages",
      },
    },
    meta: {
      allowedUserTypes: ["coord"],
    },
  },
  // Catch all route for the 404 handler
  {
    path: '/:pathMatch(.*)*',
    name: "NotFound",
    components: {
      default: () =>
        import(
          /* webpackChunkName: "AdminMatching;" */ "../views/NotFound.vue"
        ),
      Header: () =>
      import(
        /* webpackChunkName: "AdminMatching;" */ "../components/NotificationHeader.vue"
      )
    },
    props: {
      Header: {
        message: "screens.NotFound.header",
      },
    },
    meta: {
       requiresAuth: false
    },
  },
];

// Apply default meta values to routes
for (let route of routes) {
  //console.log(route);
  if ("meta" in route === false) {
    route.meta = {
      requiresAuth: true,
      forceActions: true,
      showUrgentAlert: true,
    };
  } else {
    if ("requiresAuth" in route.meta === false) {
      route.meta.requiresAuth = true;
    }
    if ("forceActions" in route.meta === false) {
      route.meta.forceActions = true;
    }
    if ("showUrgentAlert" in route.meta === false) {
      route.meta.showUrgentAlert = route.meta.requiresAuth;
    }
  }
  // we can't force actions if the user isn't authenticated
  if (route.meta.requiresAuth === false) {
    route.meta.forceActions = false;
  }
}

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// router.beforeEach() handles redirects if user is not authenticated,
// does not have the required permissions, or needs to
// perform an action before we cna serve the route
router.beforeEach((to, from) => {
  //if this is a public route, then anyone can view it
  if (to.meta.requiresAuth === false) {
    console.log("Public view");
    return true;
  }

  // For non-public routes, force authentication
  if (to.meta.requiresAuth && !router.app.user.isAuthenticated()) {
    console.log("Not authenticated");
    console.log(to);
    // For some reason props don't seem to work here, so we'll pass the redirect with the query string
    return { name: "Login", query: { redirect: to.fullPath } };
  }

  // if the user is logged in, check if we need to force an action before serving the route
  let requiredActions = router.app.user.requiresActions();
  console.log(requiredActions);
  console.log(to);
  console.log(requiredActions !== null && to.meta.forceActions);
  if (requiredActions !== null && to.meta.forceActions) {
    return { name: "Consent", query: { redirect: to.fullPath } };
  }

  // Mentees and mentors are shown the splash screen if not gong to interstitial
  if (
    from.name === "Login" &&
    (router.app.userIsMentee() || router.app.userIsMentor()) &&
    to.name !== "PeerMentorSplashScreen" &&
    to.name !== "Consent"
  ) {
    console.log("Router - redirecting to PeerMentorSplashScreen");
    return { name: "PeerMentorSplashScreen", query: { redirect: to.fullPath } };
  }

  // Check if the user is of a type permitted to see the target route; send them home if not
  // if (to.meta.requiresAuth && to.meta.allowedUserTypes.length < 3) {
  //   const userType = router.app.getUserType();
  //   if (!to.meta.allowedUserTypes.includes(userType)) {
  //     console.log("Intercepted - user type perms");
  //     return { name: router.app.getHomeRoute() };
  //   }
  // }

  // If none of the above applies, we can safely serve the requested route
  return true;
});

export default router;
